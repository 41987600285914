import React from "react";

const TeamMember = ({ name, title, description, isCEO = false, linkedinUrl }) => {
  return (
    <div
      className={`${
        isCEO ? "bg-white shadow-xl transform hover:scale-105" : "bg-white shadow-md transform hover:scale-105"
      } max-w-xs rounded-lg overflow-hidden p-6 transition-all duration-300 ease-in-out`}
    >
      <h3 className="text-2xl font-semibold text-gray-800 text-center">{name}</h3>
      <p className="text-gray-500 text-center mt-1">{title}</p>
      <p className="text-gray-700 mt-4 text-justify">{description}</p>
      {isCEO && (
        <div className="mt-4 text-center">
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            LinkedIn
          </a>
        </div>
      )}
    </div>
  );
};

const OurTeam = () => {
  const teamMembers = [
    {
      name: "Aman Kansal",
      title: "Senior Consultant",
      description: `Aman brings a wealth of knowledge in software development and AI. With a track record of successful projects, Aman plays a crucial role in delivering tailored solutions for our clients.`,
    },
    {
      name: "Sahil Jindal",
      title: "Lead Designer",
      description: `Sahil's expertise in graphic design and user experience helps create visually stunning and user-friendly interfaces, ensuring that our clients' resumes stand out.`,
    },
    {
      name: "Shashi Gupta",
      title: "CEO & Founder",
      description: `An indigenous professional with over 13 years of diverse industry experience, including a decade specializing in professional resume writing. As an IIM Lucknow alumnus with an Executive Diploma in HRM, an MBA from ICFAI, and a Software Engineering background from NIIT, she brings a unique blend of technical and strategic expertise. Owner of a successful business that has crafted over 5000 personalized, high-quality ATS resumes, helping top executives and professionals globally achieve their career goals.`,
      linkedinUrl: "https://www.linkedin.com/in/shashi-gupta-resume-writing-services/",
      isCEO: true,
    },
    {
      name: "Vanshika Jindal",
      title: "Content Strategist",
      description: `Vanshika specializes in crafting compelling content that highlights our clients' strengths and achievements, ensuring their resumes make a lasting impression.`,
    },
    {
      name: "Anuj Gupta",
      title: "Business Analyst",
      description: `Anuj leverages his analytical skills to assess market trends and client needs, providing valuable insights that drive our strategic decisions and enhance service delivery.`,
    },
  ];

  return (
    <div id="team" className="py-10 bg-gradient-to-r from-blue-50 to-green-50" style={{ paddingTop: '100px' }}>
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Meet Our Team</h1>

      <div className="flex flex-col md:flex-row justify-center">
        {/* First Column with Aman and Vanshika */}
        <div className="flex flex-col items-center mb-10 md:mr-8 space-y-8">
          <TeamMember
            name={teamMembers[0].name}
            title={teamMembers[0].title}
            description={teamMembers[0].description}
          />
          <TeamMember
            name={teamMembers[3].name}
            title={teamMembers[3].title}
            description={teamMembers[3].description}
          />
        </div>

        {/* Second Column with CEO */}
        <div className="flex flex-col items-center mb-10">
          <TeamMember
            name={teamMembers[2].name}
            title={teamMembers[2].title}
            description={teamMembers[2].description}
            linkedinUrl={teamMembers[2].linkedinUrl}
            isCEO={teamMembers[2].isCEO}
          />
        </div>

        {/* Third Column with Sahil and Anuj */}
        <div className="flex flex-col items-center mb-10 md:ml-8 space-y-8">
          <TeamMember
            name={teamMembers[1].name}
            title={teamMembers[1].title}
            description={teamMembers[1].description}
          />
          <TeamMember
            name={teamMembers[4].name}
            title={teamMembers[4].title}
            description={teamMembers[4].description}
          />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;

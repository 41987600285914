import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown, FiChevronUp, FiSearch } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => {
  return (
    <div className="border-b border-gray-300 py-4">
      <button
        className="flex justify-between items-center w-full text-left focus:outline-none"
        onClick={toggleOpen}
        aria-expanded={isOpen}
        aria-controls={`answer-${question.replace(/\s+/g, "-").toLowerCase()}`}
      >
        <span className="text-lg font-semibold">{question}</span>
        {isOpen ? (
          <FiChevronUp className="text-blue-600 transition-transform duration-200" />
        ) : (
          <FiChevronDown className="text-blue-600 transition-transform duration-200" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            id={`answer-${question.replace(/\s+/g, "-").toLowerCase()}`}
            className="mt-2 text-gray-700"
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What services do we offer?",
      answer: "We provide premium resume writing services and offer a variety of pre-made resume templates tailored to your profession.",
      isOpen: false,
    },
    {
      question: "How does the resume writing process work?",
      answer: "After placing your order, our professional writers will contact you to gather your information and preferences, ensuring a personalized resume.",
      isOpen: false,
    },
    {
      question: "Can I customize the pre-designed templates?",
      answer: "Absolutely! Our templates are fully customizable, allowing you to easily edit and personalize them to fit your style.",
      isOpen: false,
    },
    {
      question: "What is your turnaround time for resumes?",
      answer: "We typically deliver your completed resume within 3-5 business days, depending on the complexity and your requirements.",
      isOpen: false,
    }
  ]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);
  const [noResults, setNoResults] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const filtered = faqs.filter(
      (faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFaqs(filtered);
    setNoResults(filtered.length === 0);
  }, [searchQuery, faqs]);

  const toggleOpen = (index) => {
    setFaqs(
      faqs.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : { ...faq, isOpen: false }
      )
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchRef.current.blur();
    }
  };

  return (
    <div id="faq" className="max-w-3xl mx-auto px-6 py-8 bg-white shadow-lg rounded-lg" style={{ paddingTop: '100px' }}>
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">Frequently Asked Questions</h1>
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search FAQ..."
          className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchQuery}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          ref={searchRef}
          aria-label="Search FAQ"
        />
        <FiSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
      {noResults ? (
        <p className="text-center text-gray-600">No matching questions found. Please try a different search term.</p>
      ) : (
        <div className="space-y-4">
          {filteredFaqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={faq.isOpen}
              toggleOpen={() => toggleOpen(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FAQ;

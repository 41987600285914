import React, { useState } from "react";
import { FaUserTie, FaGraduationCap, FaChartLine, FaBriefcase, FaTrophy, FaCrown } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { sendServiceQuery } from '../api/api';

const ServiceSection = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedService, setSelectedService] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        callDate: "",
        callTime: "",
        service: "",
    });

    const services = [
        {
            title: "Freshers (0-1 Years)",
            icon: FaGraduationCap,
            description: "For professionals with 0-1 years of experience",
            ribbon: "New Grad",
            details: [
                { name: "Text Resume", price: "INR 1699" },
                { name: "Visual Resume", price: "INR 2099" },
                { name: "LinkedIn Profile", price: "INR 599" },
                { name: "Cover Letter", price: "INR 199" }
            ],
        },
        {
            title: "Entry Level (1-5 Years)",
            icon: FaUserTie,
            description: "For professionals with 1-5 years of experience",
            ribbon: "Rising Star",
            details: [
                { name: "Text Resume", price: "INR 1999" },
                { name: "Visual Resume", price: "INR 2599" },
                { name: "LinkedIn Profile", price: "INR 799" },
                { name: "Cover Letter", price: "INR 299" }
            ],
        },
        {
            title: "Mid-level (5-10 Years)",
            icon: FaBriefcase,
            description: "For professionals with 5-10 years of experience",
            ribbon: "Experienced",
            details: [
                { name: "Text Resume", price: "INR 2299" },
                { name: "Visual Resume", price: "INR 2799" },
                { name: "LinkedIn Profile", price: "INR 999" },
                { name: "Cover Letter", price: "INR 399" }
            ],
        },
        {
            title: "Mid-Senior Level (10-15 Years)",
            icon: FaChartLine,
            description: "For professionals with 10-15 years of experience",
            ribbon: "Expert",
            details: [
                { name: "Text Resume", price: "INR 2799" },
                { name: "Visual Resume", price: "INR 3299" },
                { name: "LinkedIn Profile", price: "INR 1199" },
                { name: "Cover Letter", price: "INR 499" }
            ],
        },
        {
            title: "Senior Level (15-20 Years)",
            icon: FaTrophy,
            description: "For professionals with 15-20 years of experience",
            ribbon: "Leader",
            details: [
                { name: "Text Resume", price: "INR 3799" },
                { name: "Visual Resume", price: "INR 4299" },
                { name: "LinkedIn Profile", price: "INR 1399" },
                { name: "Cover Letter", price: "INR 599" }
            ],
        },
        {
            title: "Top Executive (25+ Years)",
            icon: FaCrown,
            description: "For professionals with 25+ years of experience",
            ribbon: "Executive",
            details: [
                { name: "Text Resume", price: "INR 4399" },
                { name: "Visual Resume", price: "INR 4799" },
                { name: "LinkedIn Profile", price: "INR 1999" },
                { name: "Cover Letter", price: "INR 799" }
            ],
        },
    ];

    const handleBookService = (service) => {
        setSelectedService(service);
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
        setIsSubmitted(false);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            fullName: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            callDateTime: `${formData.callDate}T${formData.callTime}:00`,
            service: selectedService,
        };

        console.log("Request Data:", requestData);
        try {
            const responseMessage = await sendServiceQuery(requestData);
            console.log("Service booked successfully:", responseMessage);
            setIsSubmitted(true);
            setTimeout(() => {
                setIsFormOpen(false);
                setIsSubmitted(false);
                setFormData({
                    fullName: "",
                    email: "",
                    phone: "",
                    callDate: "",
                    callTime: "",
                    service: "",
                });
            }, 3000);
        } catch (error) {
            const errorMessage = error.message || "An unknown error occurred";
            console.error("Error while booking service:", errorMessage);
            alert(`Error: ${errorMessage}`);
        }
    };


    return (
        <div id="service" className="bg-gradient-to-r from-purple-100 to-pink-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8" style={{ paddingTop: '100px' }}>
            <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-12">Our Premium Services and Price Structure</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl relative"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <div className="absolute top-0 right-0 z-10">
                                <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-1 px-4 rounded-br-lg shadow-md">
                                    {service.ribbon}
                                </div>
                            </div>
                            <div className="p-6">
                                <div className="flex items-center justify-center w-16 h-16 bg-gradient-to-r from-blue-400 to-indigo-500 rounded-full mb-4">
                                    <service.icon className="text-white text-3xl" />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900 mb-2">{service.title}</h3>
                                <p className="text-gray-600 mb-4">{service.description}</p>
                                <ul className="mb-4">
                                    {service.details.map((detail, idx) => (
                                        <li key={idx} className="text-gray-700 mb-1">
                                            {detail.name}: <span className="font-semibold">{detail.price}</span>
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    onClick={() => handleBookService(service.title)}
                                    className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-2 px-4 rounded-md hover:from-blue-600 hover:to-indigo-700 transition-colors duration-300"
                                    aria-label={`Book ${service.title} service`}
                                >
                                    Book Service
                                </button>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>

            <AnimatePresence>
                {isFormOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
                    >
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            className="bg-white rounded-lg p-8 max-w-md w-full shadow-2xl relative"
                        >
                            <button
                                onClick={handleCloseForm}
                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition"
                            >
                                ✕
                            </button>
                            {!isSubmitted ? (
                                <>
                                    <h3 className="text-3xl font-bold mb-6 text-center text-gray-800">Book {selectedService} Service</h3>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div className="flex space-x-4">
                                            <div className="flex-1">
                                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fullName"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    onChange={handleInputChange}
                                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                                    required
                                                    aria-label="Full Name"
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                                    required
                                                    aria-label="Email"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex space-x-4">
                                            <div className="flex-1">
                                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Phone
                                                </label>
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                                    required
                                                    aria-label="Phone"
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <label htmlFor="callDate" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Call Date
                                                </label>
                                                <input
                                                    type="date"
                                                    id="callDate"
                                                    name="callDate"
                                                    value={formData.callDate}
                                                    onChange={handleInputChange}
                                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                                    required
                                                    aria-label="Call Date"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex space-x-4">
                                            <div className="flex-1">
                                                <label htmlFor="callTime" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Call Time
                                                </label>
                                                <input
                                                    type="time"
                                                    id="callTime"
                                                    name="callTime"
                                                    value={formData.callTime}
                                                    onChange={handleInputChange}
                                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                                    required
                                                    aria-label="Call Time"
                                                />
                                            </div>
                                        </div>
                                        <input type="hidden" name="service" value={selectedService} />
                                        <button
                                            type="submit"
                                            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                                            aria-label="Submit Booking"
                                        >
                                            Submit Booking
                                        </button>
                                    </form>
                                </>
                            ) : (
                                <div className="text-center">
                                    <h3 className="text-2xl font-bold text-green-600 mb-4">Success!</h3>
                                    <p className="text-gray-700">Your booking request for {selectedService} has been submitted.</p>
                                </div>
                            )}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ServiceSection;

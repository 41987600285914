import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import LandingPage from './pages/LandingPage.jsx';
import CompanyLogos from './pages/CompanyLogos.jsx';
import WhyChooseUs from './pages/WhyChooseUs.jsx'; 
import ResumeTemplates from './pages/ResumeTemplates.jsx';
import FeatureRichComponent from './pages/FeatureRichComponent.jsx';
import ServiceSection from './pages/ServiceSection.jsx';
import TestimonialCarousel from './pages/TestimonialCarousel.jsx';
import FineTuneResumeShowcase from './pages/FineTuneResumeShowcase.jsx';
import ContactForm from './pages/ContactForm.jsx';
import OurTeam from './pages/OurTeam.jsx';
import FAQ from './pages/FAQ.jsx';
import TermsAndConditions from './pages/TermsAndConditions.jsx';
import Footer from './pages/Footer';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <LandingPage />
        <CompanyLogos />
        <WhyChooseUs />
        <ResumeTemplates />
        <FeatureRichComponent />
        <ServiceSection />
        <TestimonialCarousel />
        <FineTuneResumeShowcase />
        <ContactForm />
        <OurTeam />
        <FAQ />
        <TermsAndConditions />
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight, FaStar } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const testimonials = [
    {
        id: 1,
        name: "Sreedhar Khandavilli",
        role: "20+ Years | SAP Program & Product Mgmt. | Automation & Digitization | Cloud Computing",
        content: "The right constructive words in resume making. Everyone should get a better solution provider like you. Awesome work done for me! The attention to detail in my resume has led to numerous interviews and I couldn't be happier with the outcome.",
        rating: 5
    },
    {
        id: 2,
        name: "Naman Jain",
        role: "Senior Manager, Production in Nestle India Ltd",
        content: "Shashi has designed my resume in a well-mannered and professional way. She is supportive, trained, and knows her job very well. Her insights were invaluable, and I received multiple job offers within weeks of sending out my new resume!",
        rating: 5
    },
    {
        id: 3,
        name: "Jatin Singh",
        role: "Director - Telecom Channel Sales Management",
        content: "I had the pleasure of working with Shashi recently. She is a talented writer with a keen eye for detail. Her expertise helped me craft my LinkedIn profile & my resume that effectively showcases my skills and experience. I highly recommend Shashi's resume writing services to anyone seeking a professional who can help them land their dream job.",
        rating: 5
    },
    {
        id: 4,
        name: "Isha",
        role: "Chartered Accountant",
        content: "The user interface is incredibly intuitive. Our team's productivity has skyrocketed since we started using this product. The way Shashi transformed my work experience into impactful statements was remarkable, making my resume stand out from the competition!",
        rating: 5
    },
    {
        id: 5,
        name: "Pradeep Rai",
        role: "Technical Head at KLJ Organic Qatar WLL",
        content: "It was nice collaborating with Shashi on optimizing my resume. She possesses an exceptional talent for transforming complex career narratives into clear, concise, and impactful documents.",
        rating: 5
    },
    {
        id: 6,
        name: "Rajeev Rajan",
        role: "Senior Manager Parts and accessories - TOYOTA & LEXUS KUWAIT",
        content: "Shashi's ability to tackle even the most difficult aspects of resume writing really amazed me. For others in the service industry, it can take years to acquire that kind of ability, but for her, it appeared to come effortlessly.",
        rating: 5
    }
];

const TestimonialCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(0);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    useEffect(() => {
        const interval = setInterval(() => {
            nextTestimonial();
        }, 5000);
        return () => clearInterval(interval);
    }, [currentIndex]);

    const nextTestimonial = () => {
        setDirection(1);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setDirection(-1);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 75) {
            nextTestimonial();
        } else if (touchStartX.current - touchEndX.current < -75) {
            prevTestimonial();
        }
    };

    const variants = {
        enter: (direction) => ({ x: direction > 0 ? 1000 : -1000, opacity: 0 }),
        center: { zIndex: 1, x: 0, opacity: 1 },
        exit: (direction) => ({ zIndex: 0, x: direction < 0 ? 1000 : -1000, opacity: 0 }),
    };

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, index) => (
            <FaStar key={index} className={`inline-block ${index < rating ? "text-yellow-400" : "text-gray-300"}`} />
        ));
    };

    return (
        <div id="testimonials" className="relative w-full max-w-4xl mx-auto overflow-hidden py-12" style={{ paddingTop: '100px' }}>
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">What Our Clients Say About Us</h2>
            <div
                className="flex items-center justify-center h-96 bg-blue-50 rounded-lg shadow-lg"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={currentIndex}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
                        className="absolute w-full h-full flex items-center justify-center"
                    >
                        <div
                            className="bg-white rounded-lg shadow-xl p-8 mx-4 md:mx-0 max-w-2xl"
                            role="region"
                            aria-label={`Testimonial ${currentIndex + 1} of ${testimonials.length}`}
                        >
                            <div className="text-center mb-6">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">{testimonials[currentIndex].name}</h3>
                                <p className="text-gray-600 mb-2">{testimonials[currentIndex].role}</p>
                                <div className="mb-4">{renderStars(testimonials[currentIndex].rating)}</div>
                            </div>
                            <p className="text-gray-700 text-lg italic text-center">
                                "{testimonials[currentIndex].content}"
                            </p>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
            <button
                onClick={prevTestimonial}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 ml-4 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Previous testimonial"
            >
                <FaChevronLeft className="text-gray-800 text-xl" />
            </button>
            <button
                onClick={nextTestimonial}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 mr-4 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Next testimonial"
            >
                <FaChevronRight className="text-gray-800 text-xl" />
            </button>
        </div>
    );
};

export default TestimonialCarousel;

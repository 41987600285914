import React from 'react';
import { motion } from 'framer-motion';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Helmet } from 'react-helmet';

const NavBar = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isScrolled, setIsScrolled] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-gray-800 shadow-lg' : 'bg-transparent'}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-20">
                    <div className="flex items-center">
                        <a href="#ABC" className="flex items-center text-white font-bold text-2xl">
                            <span className="mr-2 text-gray-400 font-bold text-2xl">FR</span>
                            <span className="text-white font-bold text-xl">FineTune Resume</span>
                        </a>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                            <a href="#home" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Home</a>
                            <a href="#services" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Services</a>
                            <a href="#about" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">About</a>
                            <a href="#team" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Team</a>
                            <a href="#faq" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">FAQ</a>
                            <a href="#products" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Products</a>
                            <a href="#testimonials" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Testimonials</a>
                            <a href="#contact" className="text-gray-300 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300">Contact</a>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition duration-300"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {isOpen ? (
                                <IoMdClose className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <FaBars className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className="md:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gray-800">
                        <a href="#home" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Home</a>
                        <a href="#services" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Services</a>
                        <a href="#about" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">About</a>
                        <a href="#team" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Team</a>
                        <a href="#faq" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">FAQ</a>
                        <a href="#products" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Products</a>
                        <a href="#testimonials" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Testimonials</a>
                        <a href="#contact" className="text-gray-300 hover:bg-indigo-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300">Contact</a>
                    </div>
                </div>

            )
            }
        </nav >
    );
};

const CountUpBox = ({ title, count, suffix, duration }) => {
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (value < count) {
                setValue(prev => Math.min(prev + 1, count));
            }
        }, duration * 1000);  
        return () => clearInterval(interval);
    }, [count, value, duration]);

    return (
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
        <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-bold text-indigo-400"
        >
            {value}{suffix}
        </motion.h2>
        <p className="text-lg text-gray-300 mt-2">{title}</p>
    </div>
    );
};

const LandingPage = () => {
    return (
        <div id="home" className="bg-gradient-to-b from-gray-900 to-indigo-900 min-h-screen text-white">
            <Helmet>
                <title>FineTune Resume | Professional Resume Writing Service</title>
                <meta name="description" content="We provide exceptional resume writing services tailored to your professional goals. FineTune Resume ensures your resume stands out." />
                <meta name="keywords" content="resume writing, professional resume, career growth, resume services, job applications, CV writing, resume editing, job search, professional CV, executive resume, custom resume, cover letter, resume builder, LinkedIn profile optimization, career advice, resume templates, job interview preparation, resume critique, ATS-friendly resumes, career consulting, personal branding, job market, resume format" />
            </Helmet>
            <NavBar />
            <header className="relative overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 flex items-center justify-center min-h-screen">
                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 text-center">
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: 'easeOut' }}
                            >
                                <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-7xl">
                                    <span className="block xl:inline">Exceptional Resume Writing</span>{' '}
                                    <span className="block text-indigo-400 xl:inline">For Your Career Growth</span>
                                </h1>
                                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl">
                                    We provide the best resume writing services tailored to your professional goals. FineTune Resume ensures your resume stands out and gets noticed.
                                </p>
                                <div className="mt-8 sm:mt-10 sm:flex sm:justify-center">
                                    <div className="rounded-md shadow">
                                        <a href="#contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 transition duration-300">
                                            Get started
                                        </a>
                                    </div>
                                </div>
                            </motion.div>
                        </main>
                    </div>
                </div>
            </header>
            <section className="py-16 bg-gray-900">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-center text-3xl font-bold text-white mb-8">Our Achievements</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 justify-center">
                        <CountUpBox title="Resumes Delivered" count={5000} suffix="+" duration={0.2} />
                        <CountUpBox title="Client Satisfaction" count={99} suffix="%" duration={0.75} />
                        <CountUpBox title="Countries Served" count={10} suffix="+" duration={0.5} />
                        <CountUpBox title="Years of Experience" count={10} suffix="+" duration={0.5} />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LandingPage;

import React, { useState } from "react";
import { FaUser, FaEnvelope, FaPhone, FaPen, FaSpinner, FaMapMarkerAlt } from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { sendContactEmail } from '../api/api'; // Update this with the correct path to your API file

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [submissionError, setSubmissionError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = "";
        switch (name) {
            case "fullName":
                error = value.trim() === "" ? "Full name is required" : "";
                break;
            case "email":
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                error = !emailRegex.test(value) ? "Invalid email format" : "";
                break;
            case "phone":
                error = value.trim() === "" ? "Phone number is required" : "";
                break;
            case "message":
                error = value.trim() === "" ? "Message is required" : "";
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.values(errors).filter((error) => error !== "");
        if (formErrors.length === 0) {
            setIsSubmitting(true);
            setSubmissionError("");
            try {
                const result = await sendContactEmail(formData);
                console.log(result);
                setIsDialogOpen(true);
                setFormData({ fullName: "", email: "", phone: "", message: "" });
            } catch (error) {
                setSubmissionError("Failed to send message. Please try again later.");
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div id="contact" className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4" style={{ paddingTop: '100px' }}>
            <div className="bg-white rounded-2xl shadow-2xl p-8 md:flex md:w-full max-w-5xl overflow-hidden">
                <form onSubmit={handleSubmit} className="md:w-1/2 md:pr-8">
                    <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600 tracking-tight text-center">
                        Get in Touch
                    </h2>
                    {submissionError && <p className="text-red-500 text-center mb-4">{submissionError}</p>}
                    <div className="mb-4">
                        <div className="relative">
                            <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-500" />
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className={`w-full pl-10 pr-3 py-2 rounded-lg border-2 ${errors.fullName ? 'border-red-500' : 'border-indigo-200'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200`}
                                placeholder="Full Name"
                                aria-label="Full Name"
                            />
                        </div>
                        {errors.fullName && <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>}
                    </div>
                    <div className="mb-4">
                        <div className="relative">
                            <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-500" />
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`w-full pl-10 pr-3 py-2 rounded-lg border-2 ${errors.email ? 'border-red-500' : 'border-indigo-200'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200`}
                                placeholder="Email Address"
                                aria-label="Email Address"
                            />
                        </div>
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    </div>
                    <div className="mb-4">
                        <div className="relative">
                            <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-500" />
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`w-full pl-10 pr-3 py-2 rounded-lg border-2 ${errors.phone ? 'border-red-500' : 'border-indigo-200'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200`}
                                placeholder="Phone Number"
                                aria-label="Phone Number"
                            />
                        </div>
                        {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
                    </div>
                    <div className="mb-4">
                        <div className="relative">
                            <FaPen className="absolute left-3 top-3 text-indigo-500" />
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className={`w-full pl-10 pr-3 py-2 rounded-lg border-2 ${errors.message ? 'border-red-500' : 'border-indigo-200'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200`}
                                rows="3"
                                placeholder="Your message here..."
                                aria-label="Message"
                            ></textarea>
                        </div>
                        {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                    </div>
                    <button
                        type="submit"
                        className={`w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <FaSpinner className="animate-spin inline-block mr-2" />
                                Sending...
                            </>
                        ) : (
                            "Send Message"
                        )}
                    </button>
                </form>
                <div className="md:w-1/2 mt-8 md:mt-0 md:pl-8 border-t md:border-t-0 md:border-l border-indigo-100 pt-8 md:pt-0 flex flex-col justify-between">
                    <div>
                        <h3 className="text-2xl font-bold mb-6 text-indigo-800">We're Here to Help!</h3>
                        <p className="text-gray-600 mb-4">Our team of experts is ready to assist you with any questions or concerns you may have. Don't hesitate to reach out!</p>
                        <div className="space-y-3">
                            <p className="flex items-center text-gray-700">
                                <FaPhone className="mr-3 text-indigo-500" />
                                <a href="tel:+918510910920" className="text-indigo-500 hover:underline">
                                    +91 8510910920
                                </a>
                            </p>
                            <p className="flex items-center text-gray-700">
                                <FaEnvelope className="mr-3 text-indigo-500" />
                                <a href="mailto:info@finetuneresume.com" className="text-indigo-500 hover:underline">
                                    info@finetuneresume.com
                                </a>
                            </p>
                            <p className="flex items-center text-gray-700">
                                <FaMapMarkerAlt className="mr-3 text-indigo-500" />
                                <a
                                    href="https://www.google.com/maps/search/?api=1&query=New+Delhi,India"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-indigo-500 hover:underline"
                                >
                                    New Delhi, India
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 relative overflow-hidden rounded-xl shadow-lg">
                        <img
                            src={require('../assests/web-images/contact form.avif')}
                            alt="Contact Us"
                            className="w-full h-48 object-cover rounded-xl transition duration-300 transform hover:scale-110"
                        />
                        <div className="absolute inset-0 bg-gradient-to-br from-indigo-500 to-purple-600 opacity-75 flex items-center justify-center">
                            <span className="text-white text-2xl font-bold text-center px-4">Empowering Your Career Journey with Tailored Resume Solutions!</span>
                        </div>
                    </div>
                </div>
            </div>
            <Transition appear show={isDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <Dialog.Panel className="mx-auto max-w-sm bg-white rounded-2xl p-6">
                                <Dialog.Title className="text-lg font-medium text-gray-900">Message Sent</Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Thank you for reaching out! We will get back to you shortly.
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={closeDialog}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default ContactForm;

import React, { useState } from "react";
import { FaDownload, FaEye, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import professionalTemplate from '../assests/resume-templates/Text1_199_page-0001.jpg';
import creativeTemplate from '../assests/resume-templates/Text2_199_page-0001.jpg';
import classicTemplate from '../assests/resume-templates/Text3_199_page-0001.jpg';
import minimalistTemplate from '../assests/resume-templates/TEXT4_199_page-0001.jpg';
import elegantTemplate from '../assests/resume-templates/Text5_199_page-0001.jpg';
import boldTemplate from '../assests/resume-templates/Text6_199_page-0001.jpg';
import modernTemplate from '../assests/resume-templates/Text7_199_page-0001.jpg';
import artisticTemplate from '../assests/resume-templates/Text8_199_page-0001.jpg';
import corporateTemplate from '../assests/resume-templates/Text9_199_page-0001.jpg';
import { handleRazorpayPayment } from '../api/api';
import { useNavigate } from "react-router-dom";

const ResumeTemplates = () => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();

    const templates = [
        {
            id: 1,
            name: "Professional",
            thumbnail: professionalTemplate,
            fullView: professionalTemplate,
            color: "bg-blue-500",
            price: 399
        },
        {
            id: 2,
            name: "Creative",
            thumbnail: creativeTemplate,
            fullView: creativeTemplate,
            color: "bg-green-500",
            price: 399
        },
        {
            id: 3,
            name: "Classic",
            thumbnail: classicTemplate,
            fullView: classicTemplate,
            color: "bg-purple-500",
            price: 399
        },
        {
            id: 4,
            name: "Minimalist",
            thumbnail: minimalistTemplate,
            fullView: minimalistTemplate,
            color: "bg-red-500",
            price: 399
        },
        {
            id: 5,
            name: "Elegant",
            thumbnail: elegantTemplate,
            fullView: elegantTemplate,
            color: "bg-yellow-500",
            price: 399
        },
        {
            id: 6,
            name: "Bold",
            thumbnail: boldTemplate,
            fullView: boldTemplate,
            color: "bg-orange-500",
            price: 399
        },
        {
            id: 7,
            name: "Modern",
            thumbnail: modernTemplate,
            fullView: modernTemplate,
            color: "bg-teal-500",
            price: 399
        },
        {
            id: 8,
            name: "Artistic",
            thumbnail: artisticTemplate,
            fullView: artisticTemplate,
            color: "bg-pink-500",
            price: 399
        },
        {
            id: 9,
            name: "Corporate",
            thumbnail: corporateTemplate,
            fullView: corporateTemplate,
            color: "bg-gray-500",
            price: 399
        }
    ];

    const handleViewFullResume = (template) => {
        setSelectedTemplate(template);
    };

    const handleDownload = async (templateId) => {
        const selectedTemplate = templates.find(template => template.id === templateId);
        if (selectedTemplate) {
            const paymentData = {
                amount: selectedTemplate.price,
                currency: "INR"
            };
    
            await handleRazorpayPayment(paymentData, navigate);
        } else {
            console.error("Template not found for the given ID");
        }
    };
    

    const handleCloseFullView = () => {
        setSelectedTemplate(null);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 3 >= templates.length ? 0 : prevIndex + 3
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? templates.length - 3 : prevIndex - 3
        );
    };

    return (
        <div id="products" className="bg-gradient-to-r from-gray-50 to-gray-200 min-h-screen p-4 md:p-8" style={{ paddingTop: '100px' }}>
            <h1 className="text-3xl md:text-4xl font-serif text-center mb-8 md:mb-12 text-gray-800">Resume Shop</h1>
            <p className="text-lg text-center text-gray-700 mb-6 md:mb-8">
                Explore our diverse collection of professionally designed resume templates.
                Whether you're seeking a modern, minimalist, or creative layout, our templates
                will help you stand out and make a lasting impression on potential employers.
            </p>
            <div className="relative max-w-5xl mx-auto">
                <div className="overflow-hidden rounded-lg shadow-lg">
                    <div className="flex flex-row justify-between">
                        {templates.slice(currentIndex, currentIndex + 3).map((template) => (
                            <div key={template.id} className="w-full md:w-1/3 p-2">
                                <div className="bg-white rounded-lg overflow-hidden shadow-md">
                                    <div className={`${template.color} text-white py-2 px-4 text-center`}>
                                        <span className="font-semibold">Includes all taxes</span>
                                    </div>
                                    <img
                                        src={template.thumbnail}
                                        alt={`${template.name} Template`}
                                        className="w-full h-92 object-contain transition duration-300 transform hover:scale-105"
                                    />
                                    <div className="p-4 flex flex-col items-center">
                                        <h2 className="text-xl font-sans font-semibold mb-2 text-gray-800">{template.name}</h2>
                                        <p className="text-gray-600 mb-4 font-serif flex items-center justify-center">
                                            <span className="mr-1">🇮🇳</span> Price: ₹399
                                        </p>
                                        <div className="flex flex-col space-y-2">
                                            <button
                                                onClick={() => handleViewFullResume(template)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded-full flex items-center justify-center transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                                aria-label={`View full resume for ${template.name} template`}
                                            >
                                                <FaEye className="mr-2" /> View Full Resume
                                            </button>
                                            <button
                                                onClick={() => handleDownload(template.id)}
                                                className="bg-green-500 text-white px-4 py-2 rounded-full flex items-center justify-center transition duration-300 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                                aria-label={`Download ${template.name} template`}
                                            >
                                                <FaDownload className="mr-2" /> Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    onClick={prevSlide}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    aria-label="Previous template"
                >
                    <FaChevronLeft size={24} />
                </button>

                <button
                    onClick={nextSlide}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    aria-label="Next template"
                >
                    <FaChevronRight size={24} />
                </button>
            </div>

            {selectedTemplate && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-full overflow-auto">
                        <div className="p-4">
                            <h2 className="text-2xl font-serif font-bold mb-4">{selectedTemplate.name} Template</h2>
                            <img
                                src={selectedTemplate.fullView}
                                alt={`Full view of ${selectedTemplate.name} Template`}
                                className="w-full object-contain mb-4"
                            />
                            <div className="flex justify-end">
                                <button
                                    onClick={handleCloseFullView}
                                    className="bg-red-500 text-white px-4 py-2 rounded-full transition duration-300 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResumeTemplates;

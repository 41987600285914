import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FaFileAlt,
  FaPaintBrush,
  FaFileSignature,
  FaIndustry,
  FaEdit,
  FaCheckCircle,
  FaUserTie,
  FaDollarSign,
} from "react-icons/fa";

const WhyChooseUs = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const features = [
    {
      icon: FaFileAlt,
      title: "ATS-Friendly Templates",
      description:
        "Seamlessly navigate ATS systems, boosting your chances of catching top employers' attention.",
      bgColor: "bg-blue-100",
      iconColor: "text-blue-500",
    },
    {
      icon: FaPaintBrush,
      title: "Professional and Sleek Designs",
      description:
        "Spotlight your career with sleek designs that integrate keywords and highlight achievements.",
      bgColor: "bg-green-100",
      iconColor: "text-green-500",
    },
    {
      icon: FaFileSignature,
      title: "High-Quality Resumes & Cover Letters",
      description:
        "Stand out from the competition with high-quality, professionally crafted documents.",
      bgColor: "bg-purple-100",
      iconColor: "text-purple-500",
    },
    {
      icon: FaIndustry,
      title: "Tailored for Your Industry",
      description:
        "Get resumes designed specifically for your industry, delivering relevance and precision.",
      bgColor: "bg-red-100",
      iconColor: "text-red-500",
    },
    {
      icon: FaEdit,
      title: "Easy Customization in MS Word",
      description:
        "Effortlessly customize your resume in MS Word format, making editing a breeze.",
      bgColor: "bg-yellow-100",
      iconColor: "text-yellow-500",
    },
    {
      icon: FaCheckCircle,
      title: "Ready-to-Use ATS-friendly Formats",
      description:
        "Save time with pre-designed templates optimized for Applicant Tracking Systems.",
      bgColor: "bg-indigo-100",
      iconColor: "text-indigo-500",
    },
    {
      icon: FaUserTie,
      title: "Developed with Expert Insights",
      description:
        "Leverage expert insights to enhance your job prospects and career growth.",
      bgColor: "bg-pink-100",
      iconColor: "text-pink-500",
    },
    {
      icon: FaDollarSign,
      title: "Affordable Pricing",
      description:
        "Access professional services at competitive prices, making them accessible for all.",
      bgColor: "bg-teal-100",
      iconColor: "text-teal-500",
    },
  ];

  return (
    <div className="bg-gradient-to-br from-gray-50 to-blue-100 py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-5xl font-extrabold text-gray-900 text-center mb-16 leading-tight">
          Why Choose <span className="text-blue-600">Our Services</span>
        </h2>
        <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className={`rounded-2xl shadow-xl p-8 flex flex-col items-center justify-between transition-all duration-300 ease-in-out hover:shadow-2xl border border-gray-100 ${feature.bgColor}`}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={{ minHeight: "350px" }}
            >
              <motion.div
                className={`text-5xl mb-6 ${feature.iconColor}`}
                animate={{
                  rotate: hoveredItem === index ? 360 : 0,
                  scale: hoveredItem === index ? 1.2 : 1,
                }}
                transition={{ duration: 0.5 }}
              >
                <feature.icon aria-hidden="true" />
              </motion.div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4 text-center">
                {feature.title}
              </h3>
              <p className="text-gray-600 text-center">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;

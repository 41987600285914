import React from 'react';
import '../style/CompanyLogos.css';

const CompanyLogos = () => {
    const companies = [
        { name: 'TCS', logo: require('../assests/company_logo/Flipkart logo.png') },
        { name: 'Oracle', logo: require('../assests/company_logo/HDFC logo.png') },
        { name: 'Wipro', logo: require('../assests/company_logo/accenture logo.png') },
        { name: 'HDFC Life Insurance', logo: require('../assests/company_logo/logo-rgb-black.png') },
        { name: 'Flipkart', logo: require('../assests/company_logo/oracle logo.png') },
        { name: 'Reliance', logo: require('../assests/company_logo/reliance logo.png') },
        { name: 'Accenture', logo: require('../assests/company_logo/wipro logo.png') },
    ];


    return (
        <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
                    Trusted by Professionals from Leading Companies
                </h2>

                {/* Scrolling Logos Section */}
                <div className="scroll-container">
                    <div className="scroll-content">
                        {/* Duplicate the logos for continuous scroll */}
                        {[...companies, ...companies].map((company, index) => (
                            <div key={`${company.name}-${index}`} className="logo-container">
                                <img
                                    className="h-16 w-auto object-contain"
                                    src={company.logo}
                                    alt={`${company.name} logo`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyLogos;

import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Custom marker icon
const customMarker = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const FineTuneResumeShowcase = () => {
  const [useFallbackMap, setUseFallbackMap] = useState(false);

  // Fallback logic: Check if Mapbox is reachable
  useEffect(() => {
    axios
      .get('https://api.mapbox.com')
      .then(() => setUseFallbackMap(false))
      .catch(() => setUseFallbackMap(true));
  }, []);

  return (
    <div id="about" className="bg-gradient-to-br from-gray-100 to-gray-200 min-h-screen py-12 px-4 sm:px-6 lg:px-8" style={{ paddingTop: '100px' }}>
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-extrabold text-gray-800 sm:text-5xl md:text-6xl">
            FineTune Resume
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Crafting Professional Resumes Tailored to Your Career Aspirations
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-white overflow-hidden shadow-lg rounded-lg"
        >
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 p-6">
              <h3 className="text-2xl font-semibold text-gray-900 mb-4 text-center">About Us</h3>
              <p className="text-gray-600 mb-6">
                At FineTune Resume, we specialize in crafting high-quality, professional resumes that showcase your skills, experience, and career trajectory. Whether you're a recent graduate, a mid-career professional, or an executive, our expert team provides personalized solutions tailored to your unique background and goals.
                <br /><br />
                We understand the importance of a well-written resume in today's competitive job market. Our mission is to help individuals stand out with customized resumes designed to highlight strengths and increase the chances of landing their dream job.
                <br /><br />
                Based in the vibrant city of Delhi, our services extend across India and globally. We work closely with you to ensure your resume reflects your career ambitions and helps you make a lasting impression.
              </p>
            </div>

            {/* Map Container */}
            <div className="md:w-1/2 relative">
              <MapContainer 
                center={[28.644800, 77.216721]} 
                zoom={9} 
                style={{ height: '450px', width: '100%' }}
                className="rounded-lg"
              >
                {useFallbackMap ? (
                  // Fallback to OpenStreetMap if Mapbox fails
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                ) : (
                  // Use Mapbox as the primary map provider
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=YOUR_MAPBOX_ACCESS_TOKEN`}
                    id="mapbox/streets-v11"
                    attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                  />
                )}
                <Marker position={[28.644800, 77.216721]} icon={customMarker}>
                  <Popup>
                    FineTune Resume Office <br /> Delhi, India.
                  </Popup>
                </Marker>
              </MapContainer>

              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
                <div className="flex items-center text-sm">
                  <FaMapMarkerAlt className="flex-shrink-0 mr-1.5 h-5 w-5" />
                  <p>Located in Delhi, Serving Clients Across India and Globally</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="mt-10 text-center"
        >
          {/* Add any additional content if needed */}
        </motion.div>
      </div>
    </div>
  );
};

export default FineTuneResumeShowcase;

import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import image1 from "../assests/Premium Resume SS/PREMIUMENTRYTEXT2_page-0001.jpg"; 
import image2 from "../assests/Premium Resume SS/PREMIUMSENIORLEVELTEXTRESUME_page-0001.jpg";
import image3 from "../assests/Premium Resume SS/PREMIUMSENIORLEVELVISUALRESUME_page-0001.jpg";
import image4 from "../assests/Premium Resume SS/PremiumMIDLEVELTEXTRESUME_page-0001.jpg";
import image5 from "../assests/Premium Resume SS/PremiumVisualMidLevel_page-0001.jpg";
import image6 from '../assests/Premium Resume SS/PremiumVisualResumeSamplepdf_page-0001.jpg';
import image7 from "../assests/Premium Resume SS/PremiumVisualResume2_page-0001.jpg";
import image8 from "../assests/Premium Resume SS/PremiumVisualTemplate5_page-0001.jpg";

const FeatureRichComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [isExploring, setIsExploring] = useState(false);

  const images = [image1, image2, image3, image4, image5, image6, image7, image8];

  const features = [
    "Detailed Profile Discussion with Resume Writing Expert",
    "Modern & ATS Optimized Resume Design",
    "Customized & Responsive Content",
    "Job Related Keywords",
    "Quantifiable Achievements & Responsibilities",
    "Tricky Interview Questions Handling",
    "Employment Gaps Handling",
    "3-4 Business Days Delivery",
    "Up to 3 Revisions / Iterations based on Your Feedback",
    "Editable Word File along with PDF",
    "Maximum Customer Satisfaction",
    "Resume Tailored to Industry and Role Requirements"
  ];

  // Cycle through images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [images.length]);

  // const handleExplore = () => {
  //   setIsExploring(true);
  //   setTimeout(() => setIsExploring(false), 2000);
  // };

  // Define a list of background colors for feature cards
  const featureBgColors = [
    "bg-red-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-blue-100",
    "bg-purple-100",
    "bg-pink-100",
    "bg-indigo-100",
    "bg-teal-100",
    "bg-orange-100",
    "bg-gray-100",
    "bg-lime-100",
    "bg-cyan-100"
  ];

  return (
    <div id="services" className="flex flex-col lg:flex-row items-center justify-center p-8 bg-gradient-to-br from-indigo-50 to-blue-50 rounded-xl shadow-2xl space-y-10 lg:space-y-0" style={{ paddingTop: '100px' }}>
      
      {/* Image Carousel */}
      <div className="w-full lg:w-2/5 relative overflow-hidden rounded-lg shadow-lg mb-8 lg:mb-0" style={{ height: "650px" }}>
        <img
          src={images[currentSlide]}
          alt={`Slide ${currentSlide + 1}`}
          className="w-full h-full object-contain rounded-lg transition-transform duration-1000 ease-in-out"
        />
        {/* Slide Indicators */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {images.map((_, index) => (
            <span
              key={index}
              className={`w-3 h-3 rounded-full ${currentSlide === index ? "bg-blue-500" : "bg-gray-300"}`}
            ></span>
          ))}
        </div>
      </div>

      {/* Features and Explore Button */}
      <div className="w-full lg:w-1/2 flex flex-col items-start space-y-6 lg:ml-10">
        
        {/* Heading */}
        <h2 className="text-3xl font-bold text-gray-900 mb-4 text-center lg:text-left">
          Why Choose Our Premium Services
        </h2>

        {/* Features List */}
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`flex items-start space-x-3 p-4 rounded-lg shadow ${featureBgColors[index % featureBgColors.length]}`}
            >
              <FaCheckCircle className="text-xl text-blue-500 mt-1" />
              <p className="text-gray-800">{feature}</p>
            </div>
          ))}
        </div>

        {/* Explore Button */}
        {/* <div className="w-full flex justify-center mt-4">
          <button
            onClick={handleExplore}
            className="w-full sm:w-auto bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-indigo-50"
            disabled={isExploring}
          >
            {isExploring ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Exploring...
              </span>
            ) : (
              "Explore Premium Service"
            )}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default FeatureRichComponent;

import React, { useState, useEffect } from "react";
import { FaScroll, FaShippingFast, FaExchangeAlt, FaChevronDown, FaChevronUp,FaLock } from "react-icons/fa";
import { motion } from "framer-motion";

const TermsAndConditions = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [agreed, setAgreed] = useState(false);

  const sections = [
    {
      title: "Shipping Policy",
      icon: <FaShippingFast />,
      content: "For predefined templates, the download link will be provided immediately after payment has been made. For premium services, please refer to the service details for estimated delivery times."
    },
    {
      title: "Agreement Overview",
      icon: <FaScroll />,
      content: "These Terms and Conditions constitute a legally binding agreement made between you and our company. By accessing or using our services, you agree to comply with and be bound by these terms."
    },
    {
      title: "Cancellations and Refunds",
      icon: <FaExchangeAlt />,
      content: "Due to the nature of our resume writing services, we do not offer any refunds once the order is placed. Please ensure that you are satisfied with your order details before finalizing your purchase."
    },
    {
      title: "Privacy Policy",
      icon: <FaLock />,
      content: "We value your privacy. All personal information collected is used solely for the purpose of providing our services and will never be shared with third parties without your consent."
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      if (scrollPosition + windowHeight >= docHeight - 100) {
        setAgreed(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const handleAgree = () => {
    if (agreed) {
      alert("Thank you for agreeing to our Terms and Conditions!");
    } else {
      alert("Please read the entire Terms and Conditions before agreeing.");
    }
  };

  return (
    <div  className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 p-8" style={{ paddingTop: '100px' }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden"
      >
        <div className="p-8">
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">Terms and Conditions</h1>
          <p className="text-gray-600 mb-8 text-center">Please read these terms and conditions carefully before using our services.</p>
          
          <div className="space-y-6">
            {sections.map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                  onClick={() => toggleSection(index)}
                  className="flex items-center justify-between w-full p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                >
                  <span className="flex items-center text-xl font-semibold text-gray-700">
                    <span className="mr-3 text-blue-500">{section.icon}</span>
                    {section.title}
                  </span>
                  {activeSection === index ? <FaChevronUp className="text-gray-500" /> : <FaChevronDown className="text-gray-500" />}
                </button>
                {activeSection === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="p-4 bg-white"
                  >
                    <p className="text-gray-600">{section.content}</p>
                  </motion.div>
                )}
              </motion.div>
            ))}
          </div>

          <div  id="term" className="mt-12 text-center">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
                disabled={!agreed}
              />
              <span className="ml-2 text-gray-700">I have read and agree to the Terms and Conditions</span>
            </label>
          </div>

          <div className="mt-8 text-center">
            <button
              onClick={handleAgree}
              className={`px-6 py-3 rounded-full text-white font-semibold ${agreed ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'} transition-colors duration-200`}
              disabled={!agreed}
            >
              Agree and Continue
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TermsAndConditions;
import React from "react";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import {FaHome,  FaInfoCircle} from "react-icons/fa";
import { motion } from "framer-motion";
import FooterIMG from '../assests/web-images/logo_main.png';

import {FaServicestack, FaTags, FaQuestionCircle } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Left Section - Logo */}
          <div className="space-y-4">
            <img
              src={FooterIMG}
              alt="FineTune Resume Logo"
              className="w-58 h-auto"
            />
          </div>

          {/* New About Us Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-bold mb-4">About Us</h3>
            <p className="text-sm leading-relaxed">
              At FineTune Resume, we understand that your resume is more than
              just a document - it's a reflection of your professional journey
              and aspirations. Our team of expert writers and career coaches
              work tirelessly to craft compelling narratives that highlight your
              unique skills and experiences, setting you apart in today's
              competitive job market.
            </p>
          </div>

          {/* Center Section - Quick Links */}
          <div className="space-y-4 text-center">
            <h3 className="text-2xl font-bold mb-4">Important Links</h3>
            <ul className="space-y-2">
              <li className="flex items-center justify-center space-x-2">
                <a
                  href="#home"
                  className="flex items-center space-x-2 hover:text-gray-300 transition duration-300 ease-in-out"
                  aria-label="Go to Home page"
                >
                  <FaHome className="text-pink-500" />
                  <span>Home</span>
                </a>
              </li>
              <li className="flex items-center justify-center space-x-2">
                <a
                  href="#services"
                  className="flex items-center space-x-2 hover:text-gray-300 transition duration-300 ease-in-out"
                  aria-label="Go to Services page"
                >
                  <FaServicestack className="text-blue-500" />
                  <span>Services</span>
                </a>
              </li>
              <li className="flex items-center justify-center space-x-2">
                <a
                  href="#products"
                  className="flex items-center space-x-2 hover:text-gray-300 transition duration-300 ease-in-out"
                  aria-label="Go to Products page"
                >
                  <FaTags className="text-green-500" />
                  <span>Products</span>
                </a>
              </li>
              <li className="flex items-center justify-center space-x-2">
                <a
                  href="#about"
                  className="flex items-center space-x-2 hover:text-gray-300 transition duration-300 ease-in-out"
                  aria-label="Go to About page"
                >
                  <FaInfoCircle className="text-yellow-500" />
                  <span>About</span>
                </a>
              </li>
              <li className="flex items-center justify-center space-x-2">
                <a
                  href="#faq"
                  className="flex items-center space-x-2 hover:text-gray-300 transition duration-300 ease-in-out"
                  aria-label="Go to FAQ page"
                >
                  <FaQuestionCircle className="text-red-500" />
                  <span>FAQ</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Right Section - Contact Information */}
          <div className="space-y-4 md:ml-0">
            <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
            <div className="flex items-center space-x-2">
              <motion.div
                animate={{ scale: 1.2 }}
                transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
              >
                <FaEnvelope className="text-red-500" />
              </motion.div>
              <a
                href="mailto:info@finetuneresume.com"
                className="hover:text-gray-300 transition duration-300 ease-in-out"
              >
                info@finetuneresume.com
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <FaPhone className="text-gray-400" />
              </motion.div>
              <a
                href="tel:+918510910920"
                className="hover:text-gray-300 transition duration-300 ease-in-out"
              >
                +91 8510910920
              </a>
            </div>
            <div className="flex space-x-4 mt-4">
              <a
                href="https://wa.me/918510910920"
                aria-label="Chat with us on WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl hover:text-green-400 transition duration-300 ease-in-out"
              >
                <FaWhatsapp style={{ color: "#25D366" }} />
              </a>
              <a
                href="https://wa.me/918510910920"
                aria-label="Contact us on Botim"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl hover:text-blue-400 transition duration-300 ease-in-out"
              >
                <img src="https://www.svgrepo.com/show/277691/botim.svg" alt="Botim" style={{ width: "24px", height: "24px" }} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer bottom */}
        <div className="mt-8 pt-8 border-t border-gray-700">
          <p className="text-sm text-center">
            © {currentYear} FineTune Resume. All rights reserved.
          </p>
          <div className="text-xs text-center mt-2 text-gray-400 overflow-hidden">
            <div className="animate-marquee whitespace-nowrap">
              Disclaimer: Getting a job is based on many other factors. We
              neither provide any job consultancy or job search facility.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


const BASE_URL = "http://192.168.101.3:8080/";

// Send Contact Email
export const sendContactEmail = async (emailData) => {
  try {
    const response = await fetch(`${BASE_URL}/mail/sendContact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.text();
    return result;
  } catch (error) {
    throw error;
  }
};

// Send Service Query
export const sendServiceQuery = async (serviceData) => {
  try {
    const response = await fetch(`${BASE_URL}/mail/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(serviceData),
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${responseText}`);
    }

    const responseText = await response.text();
    return responseText;
  } catch (error) {
    throw error;
  }
};

// Create Order for Razorpay Payment
export const createRazorpayOrder = async (paymentData) => {
  try {
    const payload = {
      amount: paymentData.amount * 100, 
      currency: paymentData.currency,
    };

    const response = await fetch(`${BASE_URL}api/payments/create-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const handleRazorpayPayment = async (paymentData, navigate) => {
  try {
    const { orderId, amount, currency } = await createRazorpayOrder(paymentData);

    const options = {
      key_id: "rzp_test_AsXY88QOXjWbth", 
      amount: amount,
      currency: currency,
      order_id: orderId,
      name: "Fine Tune Resume",
      description: "Payment for resume service",
      handler: (response) => {
        navigate('/payment-confirmation', {
          state: {
            paymentStatus: 'success',
            orderAmount: amount / 100,
            orderId: orderId,
            paymentId: response.razorpay_payment_id
          }
        });
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options); 
    rzp.on('payment.failed', (response) => {
      alert("Payment failed. Please try again.");
    });

    rzp.open(); 
  } catch (error) {
    alert("Payment failed. Please try again.");
  }
};
